import React from "react"
import {
  Drawer as ChDrawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  VStack,
  Divider,
  Button,
  chakra,
  HStack,
  useColorModeValue,
} from "@chakra-ui/react"
import { JoinToUs } from "./JoinToUs"
import { Icon } from "./Icon"
import { mdiDomain, mdiEmail } from "@mdi/js"

interface IProps {
  isOpen: boolean
  onClose: () => void
}

const Drawer = ({ isOpen, onClose }: IProps) => {
  const linkHoverColor = useColorModeValue("primary.500", "primary.200")
  const linkColor = useColorModeValue("gray.700", "gray.200")
  const iconColor = useColorModeValue("gray.500", "gray.400")

  return (
    <ChDrawer isOpen={isOpen} placement="right" size="full" onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Menu</DrawerHeader>
        <DrawerBody as="aside">
          <VStack pt={2} spacing={6} display="stretch">
            <VStack spacing={3} align="flex-start">
              <chakra.a
                fontWeight="600"
                color={linkColor}
                _hover={{ color: linkHoverColor }}
                transition="all .2s ease-in-out"
                href="https://zamowienia.sidaspzp.pl/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <HStack spacing={3}>
                  <Icon path={mdiDomain} color={iconColor} />
                  <div>Platforma PZP</div>
                </HStack>
              </chakra.a>
              <chakra.a
                fontWeight="600"
                color={linkColor}
                _hover={{ color: linkHoverColor }}
                transition="all .2s ease-in-out"
                href="https://zamowienia.sidaspzp.pl/kontakt"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                <HStack spacing={3}>
                  <Icon path={mdiEmail} color={iconColor} />
                  <div>Kontakt</div>
                </HStack>
              </chakra.a>
            </VStack>
            <Divider />
            <JoinToUs
              columns={1}
              py={0}
              px={0}
              gap={4}
              background="transparent"
            />
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </ChDrawer>
  )
}

export { Drawer }
