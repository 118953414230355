import React, { ReactNode } from "react"
import { Link } from "gatsby"
import {
  Box,
  HStack,
  SimpleGrid,
  useColorModeValue,
  useTheme,
  chakra,
  SimpleGridProps,
  LinkProps,
} from "@chakra-ui/react"
import {
  mdiExcavator,
  mdiLogin,
  mdiOfficeBuilding,
  mdiTextBoxCheckOutline,
} from "@mdi/js"

import { Icon } from "./Icon"

// @ts-ignore
import executor_instruction from "../assets/executor_instruction.pdf?url"

interface ILinkDescriptionProps {
  path: string
  children: ReactNode
  title: string
}

const LinkDescription = ({ path, title, children }: ILinkDescriptionProps) => {
  const iconColor = useColorModeValue("primary.600", "primary.200")
  const sectionDescriptionColor = useColorModeValue("gray.500", "gray.200")

  return (
    <HStack align="flex-start" spacing={4}>
      <Icon color={iconColor} path={path} />
      <Box ml={4}>
        <chakra.p fontSize="sm" fontWeight="700">
          {title}
        </chakra.p>
        <chakra.p
          mt={1}
          fontSize="sm"
          color={sectionDescriptionColor}
          fontWeight="400"
        >
          {children}
        </chakra.p>
      </Box>
    </HStack>
  )
}

interface IExernalLink extends LinkProps {
  children: ReactNode
}

const ExternalLink = ({ children, ...props }: IExernalLink) => {
  const sectionBgColor = useColorModeValue("gray.50", "gray.700")

  return (
    <chakra.li>
      <chakra.a
        p={4}
        display="flex"
        alignItems="start"
        rounded="lg"
        _hover={{ bg: sectionBgColor }}
        transition="all .2s ease-in-out"
        target="_blank"
        rel="noopener noreferrer"
        {...props}
      >
        {children}
      </chakra.a>
    </chakra.li>
  )
}

const InternalLink = ({ children, href, ...props }: IExernalLink) => {
  const sectionBgColor = useColorModeValue("gray.50", "gray.700")

  return (
    <chakra.li>
      <chakra.a
        as={Link}
        p={4}
        display="flex"
        alignItems="start"
        rounded="lg"
        _hover={{ bg: sectionBgColor }}
        transition="all .2s ease-in-out"
        target="_blank"
        rel="noopener noreferrer"
        to={href}
        {...props}
      >
        {children}
      </chakra.a>
    </chakra.li>
  )
}

const JoinToUs = ({ columns = 4, ...props }: SimpleGridProps) => {
  const { colors } = useTheme()
  const headerBackground = useColorModeValue("white", "gray.800")
  const borderColor = useColorModeValue(colors.gray[200], colors.gray[600])

  return (
    <chakra.nav>
      <SimpleGrid
        columns={columns}
        as="ul"
        listStyleType="none"
        pos="relative"
        gap={8}
        py={8}
        px={12}
        background={headerBackground}
        borderBottom={`1px solid ${borderColor}`}
        zIndex={2}
        {...props}
      >
        <InternalLink href="#signup-section">
          <LinkDescription
            title="Rejestracja wykonawcy"
            path={mdiExcavator}
          >
            Śledź postępowania, składaj oferty i prowadź dialog z zamawiającymi.
          </LinkDescription>
        </InternalLink>
        <InternalLink href="#signup-section">
          <LinkDescription
            path={mdiOfficeBuilding}
            title="Rejestracja zamawiającego"
          >
            Zautomatyzuj obsługę procesów zamówień publicznych dzięki
            Internetowi.
          </LinkDescription>
        </InternalLink>
        <InternalLink href="/instrukcje">
          <LinkDescription
            title="Instrukcja wykonawcy"
            path={mdiTextBoxCheckOutline}
          >
            Dowiedz się jak możesz korzystać z naszej platformy jako wykonawca.
          </LinkDescription>
        </InternalLink>
        <ExternalLink href="https://app.sidaspzp.pl/">
          <LinkDescription title="Masz już konto? Zaloguj się" path={mdiLogin}>
            Zaloguj się do systemu by cieszyć się możliwościami platformy PZP.
          </LinkDescription>
        </ExternalLink>
      </SimpleGrid>
    </chakra.nav>
  )
}

export { JoinToUs }
