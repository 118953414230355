import React from "react"
import {
  chakra,
  Heading,
  Text,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react"

const PZP_APP_URL = "https://app.sidaspzp.pl"

const PZPLinks = () => {
  const activeColor = useColorModeValue("primary.500", "primary.200")
  const headingColor = useColorModeValue("primary.500", "primary.200")
  const textColor = useColorModeValue("gray.700", "white")

  const links = [
    {
      href: `${PZP_APP_URL}/rejestracja/zamawiajacy`,
      text: "Zarejestruj zamawiającego",
    },
    {
      href: `${PZP_APP_URL}/rejestracja/wykonawca`,
      text: "Zarejestruj wykonawcę",
    },
    {
      href: PZP_APP_URL,
      text: "Zaloguj się",
    },
  ]

  return (
    <chakra.div>
      <Heading
        as="h5"
        color={headingColor}
        fontSize={{
          base: "lg",
          lg: "xl",
        }}
        mb={4}
      >
        Platforma PZP
      </Heading>
      <nav>
        <VStack spacing={2} as="ul" ml={0} listStyleType="none" align="start">
          {links.map(({ text, href }) => (
            <chakra.li key={href}>
              <a href={href} target="_blank" rel="noopener noreferrer">
                <Text
                  fontSize={{ base: "sm", lg: "md" }}
                  color={textColor}
                  css={`
                    transition: all 0.2s ease-in-out;
                    &:hover {
                      color: ${activeColor};
                    }
                  `}
                >
                  {text}
                </Text>
              </a>
            </chakra.li>
          ))}
        </VStack>
      </nav>
    </chakra.div>
  )
}

export { PZPLinks }
