import React from "react"
import { Link } from "gatsby"
import {
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Spacer,
  useColorMode,
  useColorModeValue,
  chakra,
  ButtonGroup,
} from "@chakra-ui/react"
import {
  MoonIcon,
  SunIcon,
  ChevronDownIcon,
  HamburgerIcon,
} from "@chakra-ui/icons"
import { StaticImage } from "gatsby-plugin-image"

import { JoinToUs } from "./JoinToUs"

const Header = ({ onOpen }: { onOpen: () => void }) => {
  const { toggleColorMode: toggleMode, colorMode } = useColorMode()

  const SwitchIcon = useColorModeValue(MoonIcon, SunIcon)
  const headerBackground = useColorModeValue("white", "gray.800")
  const linkHoverColor = useColorModeValue("primary.500", "primary.200")
  const linkColor = useColorModeValue("gray.600", "gray.300")

  return (
    <chakra.header
      minH={{ base: "42px", md: "70px" }}
      w="full"
      bg={headerBackground}
      px={{ base: 2, sm: 4 }}
      py={{ base: 2, md: 4 }}
      zIndex={2}
      position="fixed"
    >
      <Flex align="center" justify="space-between" mx="auto">
        <Link to="/" aria-label="Strona domowa">
          {colorMode === "dark" ? (
            <StaticImage
              src="../images/logo_white.png"
              height={40}
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="Platforma zamówień publicznych PZP"
            />
          ) : (
            <StaticImage
              src="../images/logo.png"
              height={40}
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="Platforma zamówień publicznych PZP"
            />
          )}
        </Link>
        <Box pl={4} display={{ base: "none", md: "inline-flex" }}>
          <HStack spacing={1}>
            <Box role="group">
              <Button
                bg={headerBackground}
                color={linkColor}
                alignItems="center"
                fontSize="md"
                _hover={{ color: linkHoverColor }}
                _focus={{ boxShadow: "none" }}
                rightIcon={<ChevronDownIcon />}
              >
                Dołącz do nas
              </Button>
              <Box
                pos="absolute"
                left={0}
                w="full"
                display="none"
                _groupHover={{ display: "block" }}
              >
                <JoinToUs />
              </Box>
            </Box>

            <HStack spacing={6}>
              <chakra.a
                fontWeight="600"
                color={linkColor}
                _hover={{ color: linkHoverColor }}
                transition="all .2s ease-in-out"
                href="https://zamowienia.sidaspzp.pl/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Platforma PZP
              </chakra.a>
              <chakra.a
                fontWeight="600"
                color={linkColor}
                _hover={{ color: linkHoverColor }}
                transition="all .2s ease-in-out"
                href="https://zamowienia.sidaspzp.pl/kontakt"
                target="_blank"
                rel="noopener noreferrer"
              >
                Kontakt
              </chakra.a>
            </HStack>
          </HStack>
        </Box>
        <Spacer />
        <ButtonGroup spacing={2} alignItems="center">
          <Button
            display={{ base: "none", md: "flex" }}
            colorScheme="primary"
            size="sm"
            as={chakra.a}
            href="https://app.sidaspzp.pl/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Zaloguj się
          </Button>
          <IconButton
            fontSize="lg"
            aria-label="Zmień tryb"
            variant="ghost"
            onClick={toggleMode}
            icon={<SwitchIcon />}
          />
          <IconButton
            display={{ base: "block", md: "none" }}
            fontSize="lg"
            aria-label="Otwórz menu"
            variant="ghost"
            icon={<HamburgerIcon />}
            onClick={onOpen}
          />
        </ButtonGroup>
      </Flex>
    </chakra.header>
  )
}

export { Header }
