import React from "react"
import {
  chakra,
  Divider,
  Flex,
  FlexProps,
  Text,
  Button,
} from "@chakra-ui/react"
import { mdiArrowUp } from "@mdi/js"

import { Icon } from "../Icon"

interface IProps extends FlexProps {}

const BottomBeam = (props: IProps) => {
  return (
    <>
      <Divider mt={10} />
      <Flex py={2} justify="space-between" {...props}>
        <chakra.div>
          <Text fontSize="sm">
            © {new Date().getFullYear().toString()} - Wszelkie prawa zastrzeżone
          </Text>
        </chakra.div>
        <Button
          leftIcon={<Icon path={mdiArrowUp} size="14px" />}
          colorScheme="primary"
          size="xs"
          variant="outline"
          onClick={() => {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            })
          }}
        >
          Do góry
        </Button>
      </Flex>
    </>
  )
}

export { BottomBeam }
