import React, { ReactNode } from "react"
import { chakra, ChakraProps } from "@chakra-ui/react"

interface IProps extends ChakraProps {
  id?: string;
  children: ReactNode
}

const Section = ({ children, ...props }: IProps) => {
  return (
    <chakra.section
      m="0 auto"
      px={{ base: 3, md: 6, xl: 0 }}
      maxW="1200px"
      mt={{ base: 16, lg: 24 }}
      {...props}
    >
      {children}
    </chakra.section>
  )
}

export { Section }
