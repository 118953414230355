/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { ReactNode } from "react"
import { chakra, useDisclosure } from "@chakra-ui/react"

import { Header } from "./Header"
import { Footer } from "./Footer"
import { Drawer } from "./Drawer"

interface IProps {
  children: ReactNode
}

const Layout = ({ children }: IProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Drawer isOpen={isOpen} onClose={onClose} />
      <chakra.div position="relative">
        <Header onOpen={onOpen} />
        <chakra.main overflowX="hidden">{children}</chakra.main>
        <Footer />
      </chakra.div>
    </>
  )
}

export { Layout }
