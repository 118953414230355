import React from "react"
import { Text, VStack, chakra, Heading } from "@chakra-ui/react"
import { useColorModeValue } from "@chakra-ui/react"

import { mdiAccountMultiple, mdiEmail, mdiFormatListBulleted } from "@mdi/js"

const PZP_PUBLIC_URL = "https://zamowienia.sidaspzp.pl"

const PublicLinks = () => {
  const activeColor = useColorModeValue("primary.500", "primary.200")
  const headingColor = useColorModeValue("primary.500", "primary.200")
  const textColor = useColorModeValue("gray.700", "white")

  const links = [
    {
      href: "zamowienia?offset=0&order=-submissionDate",
      path: "zamowienia",
      text: "Lista zamówień",
      iconPath: mdiFormatListBulleted,
    },
    {
      href: "zamawiajacy?offset=0",
      path: "zamawiajacy",
      text: "Lista zamawiających",
      iconPath: mdiAccountMultiple,
    },
    {
      href: "kontakt",
      path: "kontakt",
      text: "Kontakt",
      iconPath: mdiEmail,
    },
  ]

  return (
    <chakra.div>
      <Heading
        as="h5"
        color={headingColor}
        fontSize={{
          base: "lg",
          lg: "xl",
        }}
        mb={4}
      >
        Strona publiczna
      </Heading>
      <nav>
        <VStack spacing={2} as="ul" ml={0} listStyleType="none" align="start">
          {links.map(({ text, href }) => (
            <chakra.li key={href}>
              <chakra.a
                href={`${PZP_PUBLIC_URL}/${href}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Text
                  fontSize={{ base: "sm", lg: "md" }}
                  color={textColor}
                  css={`
                    transition: all 0.2s ease-in-out;
                    &:hover {
                      color: ${activeColor};
                    }
                  `}
                >
                  {text}
                </Text>
              </chakra.a>
            </chakra.li>
          ))}
        </VStack>
      </nav>
    </chakra.div>
  )
}

export { PublicLinks }
