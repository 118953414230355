import React from "react"
import { chakra, ChakraProps, useColorMode } from "@chakra-ui/react"
import { StaticImage } from "gatsby-plugin-image"

interface IProps extends ChakraProps {}

const Logo = (props: IProps) => {
  const { colorMode } = useColorMode()

  return (
    <chakra.div {...props}>
      {colorMode === "dark" ? (
        <StaticImage
          src="../../images/logo_white.png"
          height={40}
          formats={["auto", "webp", "avif"]}
          alt="Platforma zamówień publicznych PZP"
        />
      ) : (
        <StaticImage
          src="../../images/logo.png"
          height={40}
          formats={["auto", "webp", "avif"]}
          alt="Platforma zamówień publicznych PZP"
        />
      )}
    </chakra.div>
  )
}

export { Logo }
