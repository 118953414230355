import React from "react"
import { chakra, Grid, GridItem, Divider, Flex } from "@chakra-ui/react"

import { Logo } from "./Logo"
import { PublicLinks, Contact, PZPLinks } from "./Sections"
import { BottomBeam } from "./BottomBeam"

const Footer = () => {
  return (
    <chakra.footer my={{ base: 3, md: 10, xl: 16 }} px={{ base: 3, md: 6 }}>
      <chakra.div maxWidth="1440px" margin="0 auto">
        <Divider mb={6} />
        <Grid
          templateColumns={{
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
          }}
          rowGap={8}
        >
          <GridItem as={Flex} colSpan={{ base: 1, md: 3, lg: 1 }}>
            <Logo m={{ base: "0 auto", md: "0 auto", lg: "0" }} />
          </GridItem>
          <PublicLinks />
          <PZPLinks />
          <Contact />
        </Grid>
        <BottomBeam />
      </chakra.div>
    </chakra.footer>
  )
}

export { Footer }
