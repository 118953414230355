import React from "react"
import {
  chakra,
  Flex,
  Heading,
  Text,
  VStack,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react"
import { mdiEmail, mdiHome, mdiPhone } from "@mdi/js"

import { Icon } from "../../Icon"

const Contact = () => {
  const headingFontSize = useBreakpointValue({
    base: "lg",
    lg: "xl",
  })
  const textFontSize = useBreakpointValue({ base: "sm", lg: "md" })
  const headingColor = useColorModeValue("primary.500", "primary.200")
  const textColor = useColorModeValue("gray.700", "white")
  const linkColor = useColorModeValue("blue.700", "blue.200")
  const textDecoration = useColorModeValue("none", "none")

  return (
    <chakra.div>
      <Heading as="h5" fontSize={headingFontSize} mb={4} color={headingColor}>
        Kontakt - Madkom SA
      </Heading>
      <VStack spacing={2} as="ul" ml={0} listStyleType="none" align="start">
        <Flex>
          <Icon path={mdiHome} color="primary" />
          <Text color={textColor} ml={3} fontSize={textFontSize}>
            al. Zwycięstwa 96/98, 81-451 Gdynia
          </Text>
        </Flex>
        <Flex>
          <Icon path={mdiPhone} color="primary" />
          <Text color={textColor} ml={3} fontSize={textFontSize}>
            <chakra.a
              color={linkColor}
              textDecoration={textDecoration}
              href={`tel:58 712 60 20`}
            >
              58 712 60 20
            </chakra.a>
          </Text>
        </Flex>
        <Flex>
          <Icon path={mdiEmail} color="primary" />
          <Text color={textColor} ml={3} fontSize={textFontSize}>
            <chakra.a
              color={linkColor}
              textDecoration={textDecoration}
              href={`mailto:kontakt@sidaspzp.pl`}
            >
              kontakt@sidaspzp.pl
            </chakra.a>
          </Text>
        </Flex>
      </VStack>
    </chakra.div>
  )
}

export { Contact }
